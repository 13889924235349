<template>
    <div>
        <ts-page-title
            :title="$t('shopCompensateReport.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('shopCompensateReport.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-loading-banner :loading="loading">
                <ts-panel-wrapper>
                    <div class="tw-flex tw-space-x-4 tw-items-start">
                        <div class="tw-space-y-2" style="width: 230px">
                            <label class="required">{{
                                $t("shopCompensateReport.shopClearDate")
                            }}</label>
                            <a-range-picker
                                v-model="model.date_range"
                                :allowClear="false"
                                format="DD-MM-YYYY"
                                valueFormat="DD-MM-YYYY"
                                style="width: 100%"
                            />
                        </div>
                        <div
                            class="tw-space-y-2"
                            style="width: 230px"
                            v-if="$whois.admin()"
                        >
                            <label>{{
                                $t("shopCompensateReport.shopName")
                            }}</label>
                            <a-select
                                show-search
                                v-model="model.shop_id"
                                mode="multiple"
                                style="width: 100%"
                                :default-active-first-option="false"
                                :show-arrow="false"
                                :filter-option="false"
                                :not-found-content="null"
                                :placeholder="
                                    $t('shopCompensateReport.typeToSearch')
                                "
                                @search="handleShopSearch"
                            >
                                <a-select-option
                                    v-for="c in shop"
                                    :key="c.shop_id"
                                >
                                    {{ c.shop_name }}
                                </a-select-option>
                            </a-select>
                        </div>
                        <div class="tw-space-y-2" style="width: 230px">
                            <label class="required">{{
                                $t("shopCompensateReport.type")
                            }}</label>
                            <br />
                            <a-radio-group
                                v-model="model.report_type"
                                :default-value="1"
                                button-style="solid"
                                @change="fetchData"
                            >
                                <a-radio-button :value="1">
                                    {{ $t("shopCompensateReport.detail") }}
                                </a-radio-button>
                                <a-radio-button :value="2">
                                    {{ $t("shopCompensateReport.summary") }}
                                </a-radio-button>
                            </a-radio-group>
                        </div>
                        <ts-button
                            class="tw-mt-5"
                            color="primary"
                            @click.prevent="fetchData"
                            >{{ $t("preview") }}</ts-button
                        >
                        <ts-button
                            class="tw-mt-5"
                            outline
                            color="info"
                            @click.prevent="pdf"
                            >{{ $t("pdf") }}</ts-button
                        >
                    </div>
                </ts-panel-wrapper>
                <hr />
                <ts-panel-wrapper>
                    <ShopCompensateComponent
                        :resources="resources"
                        v-if="model.report_type == 1"
                    />
                    <ShopCompensateSummaryComponent
                        :resources="resources"
                        v-if="model.report_type == 2"
                    />
                </ts-panel-wrapper>
            </ts-loading-banner>
        </ts-panel>

        <!-- preview pdf -->
        <a-modal
            :title="$t('pdf')"
            width="50%"
            v-model="preview_pdf"
            :footer="null"
        >
            <ts-preview-pdf v-model="waiting" :src="src" />
        </a-modal>
    </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import { debounce } from "lodash";
import ShopCompensateComponent from "./components/shop-compensate-component.vue";
import ShopCompensateSummaryComponent from "./components/shop-compensate-summary-component.vue";
export default {
    name: "shopCompensateReport",
    components: {
        ShopCompensateComponent,
        ShopCompensateSummaryComponent
    },
    data() {
        return {
            loading: false,
            preview_pdf: false,
            waiting: false,
            src: "",
            shop: [],
            model: {
                date_range: [
                    moment().format("DD-MM-YYYY"),
                    moment().format("DD-MM-YYYY")
                ],
                shop_id: undefined,
                report_type: 1
            },
            resources: []
        };
    },
    created() {
        this.handleShopSearch();
    },
    methods: {
        ...mapActions("report/shopCompensate", ["fetch", "toPdf"]),
        ...mapActions("report/formViewModel", ["fetchFormView"]),
        fetchData() {
            this.loading = true;
            this.resources = [];
            this.fetch(this.model)
                .then(response => {
                    this.resources = response.data;
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        pdf() {
            this.preview_pdf = true;
            this.waiting = true;
            this.toPdf(this.model)
                .then(response => {
                    this.src = response.url;
                })
                .catch(() => {
                    this.preview_pdf = false;
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        handleShopSearch: debounce(function(value) {
            this.loading = true;
            this.fetchFormView({
                fnName: "shop",
                search_shop: value
            })
                .then(response => {
                    this.shop = response.data["shop"];
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        }, 500)
    }
};
</script>
