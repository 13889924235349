<template>
    <div style="min-height: 300px; max-height: 70vh" class="tw-overflow-scroll">
        <table class="tw-w-full table">
            <thead>
                <tr>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-8 tw-text-center"
                        rowspan="2"
                        valign="middle"
                    >
                        No
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-24 tw-whitespace-nowrap"
                        rowspan="2"
                        valign="middle"
                    >
                        Transaction Date
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-24"
                        rowspan="2"
                        valign="middle"
                    >
                        Ref.Voucher
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-24"
                        rowspan="2"
                        valign="middle"
                    >
                        Shop Name
                    </th>
                    <!-- <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-20"
                        rowspan="2"
                        valign="middle"
                    >
                        FX Rate
                    </th> -->
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-32"
                        rowspan="2"
                        valign="middle"
                    >
                        COD
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-48"
                        rowspan="2"
                        valign="middle"
                    >
                        Total Fee
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-48"
                        rowspan="2"
                        valign="middle"
                    >
                        Amount To be Clear
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-52 tw-text-center"
                        colspan="2"
                    >
                        Cleared
                    </th>
                </tr>
                <tr>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-32 tw-text-center"
                    >
                        USD
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-32 tw-text-center"
                    >
                        KHR
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in resources.group" :key="index">
                    <td class="tw-text-center">{{ index + 1 }}</td>
                    <td>{{ item.transaction_date }}</td>
                    <td>{{ item.ref_voucher_shop_clear }}</td>
                    <td>{{ item.shop_name }}</td>
                    <!-- <td> <ts-accounting-format  :digit="0" currency="៛" :value="item.fx_rate" /> </td> -->
                    <td>
                        <ts-accounting-format
                            :digit="2"
                            :value="item.cod_amount"
                        />
                    </td>
                    <td>
                        <ts-accounting-format
                            :digit="2"
                            :value="item.total_fee"
                        />
                    </td>
                    <td>
                        <ts-accounting-format
                            :digit="2"
                            :value="item.amount_tobe_clear"
                        />
                    </td>
                    <td>
                        <ts-accounting-format
                            :digit="2"
                            :value="item.cod_amount_usd_cleared"
                        />
                    </td>
                    <td>
                        <ts-accounting-format
                            :digit="0"
                            currency="៛"
                            :value="item.cod_amount_khr_cleared"
                        />
                    </td>
                </tr>
            </tbody>
            <tfoot v-if="resources.amount_group">
                <tr>
                    <td colspan="4" class="tw-text-right">
                        <strong>Grand Total:</strong>
                    </td>
                    <td class="tw-text-right">
                        <ts-accounting-format
                            currency="$"
                            :digit="2"
                            :value="resources.amount_group.amount_cod"
                            class="tw-font-extrabold tw-text-blue-600"
                        />
                    </td>
                    <td class="tw-text-right">
                        <ts-accounting-format
                            currency="$"
                            :digit="2"
                            :value="resources.amount_group.total_fee"
                            class="tw-font-extrabold tw-text-blue-600"
                        />
                    </td>
                    <td class="tw-text-right">
                        <ts-accounting-format
                            currency="$"
                            :digit="2"
                            :value="resources.amount_group.amount_tobe_clear"
                            class="tw-font-extrabold tw-text-blue-600"
                        />
                    </td>
                    <td class="tw-text-right">
                        <ts-accounting-format
                            currency="$"
                            :digit="2"
                            :value="
                                resources.amount_group.cod_amount_usd_cleared
                            "
                            class="tw-font-extrabold tw-text-blue-600"
                        />
                    </td>
                    <td class="tw-text-right">
                        <ts-accounting-format
                            currency="៛"
                            :digit="0"
                            :value="
                                resources.amount_group.cod_amount_khr_cleared
                            "
                            class="tw-font-extrabold tw-text-blue-600"
                        />
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>
import { sumBy } from "lodash";
export default {
    name: "shopCompensateComponentSummary",
    props: ["resources"],
    methods: {
        sumByColumn(resources, column) {
            return sumBy(resources, column);
        },
        sumByShop(items) {
            return {
                cod_amount: sumBy(items, "cod_amount"),
                total_fee: sumBy(items, "total_fee"),
                amount_tobe_clear: sumBy(items, "amount_tobe_clear"),
                cod_amount_usd_cleared: sumBy(items, "cod_amount_usd_cleared"),
                cod_amount_khr_cleared: sumBy(items, "cod_amount_khr_cleared")
            };
        }
    }
};
</script>

<style lang="css" scoped>
::-webkit-scrollbar {
    width: 8px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: rgb(161, 3, 3);
}
.table > thead {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 999;
    background-color: rgba(59, 130, 246);
}
.table > tbody > tr > td {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    border: none !important;
}

.table > tfoot {
    position: sticky;
    bottom: 0;
    z-index: 1;
    background-color: white;
}
</style>
