<template>
    <div style="min-height: 300px; max-height: 70vh" class="tw-overflow-scroll">
        <table class="tw-w-full table">
            <thead>
                <tr>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-40 tw-whitespace-nowrap"
                        rowspan="2"
                        valign="middle"
                    >
                        Booking Number
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-whitespace-nowrap"
                        rowspan="2"
                        valign="middle"
                    >
                        Delivery Number
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-36"
                        rowspan="2"
                        valign="middle"
                    >
                        Receiver Phone
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-36 tw-whitespace-nowrap"
                        rowspan="2"
                        valign="middle"
                    >
                        Receiver Location
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-24"
                        rowspan="2"
                        valign="middle"
                    >
                        Package #
                    </th>
                    <!-- <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-20"
                        rowspan="2"
                        valign="middle"
                    >
                        FX Rate
                    </th> -->
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-32"
                        rowspan="2"
                        valign="middle"
                    >
                        COD
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-48"
                        rowspan="2"
                        valign="middle"
                    >
                        Total Fee
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-48"
                        rowspan="2"
                        valign="middle"
                    >
                        Amount To be Clear
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-52 tw-text-center"
                        colspan="2"
                    >
                        Cleared
                    </th>
                </tr>
                <tr>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-32 tw-text-center"
                    >
                        USD
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-32 tw-text-center"
                    >
                        KHR
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(dateItem, indexDate) in resources.group">
                    <tr class="tw-bg-gray-200" :key="indexDate">
                        <td
                            class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                            colspan="10"
                        >
                            <b>Date:</b> {{ indexDate }}
                        </td>
                    </tr>
                    <template v-for="(shopItem, shopName) in dateItem">
                        <tr
                            class="tw-bg-gray-200"
                            :key="indexDate + '_' + shopName"
                        >
                            <td
                                class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                                colspan="10"
                            >
                                <b>Shop Name:</b> {{ shopName }}
                            </td>
                        </tr>
                        <template v-for="(voucherItem, voucher) in shopItem">
                            <tr
                                class="tw-bg-gray-200"
                                :key="
                                    indexDate + '_' + shopName + '_' + voucher
                                "
                            >
                                <td
                                    class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                                    colspan="10"
                                >
                                    <b>Voucher No:</b> {{ voucher }}
                                </td>
                            </tr>
                            <template v-for="(item, index) in voucherItem">
                                <tr
                                    class="tw-border-b"
                                    :key="index + '_' + item.delivery_number"
                                >
                                    <td>{{ item['booking_number'] }}</td>
                                    <td>{{ item['delivery_number'] }}</td>
                                    <td>{{ item['receiver_phone'] }}</td>
                                    <td>{{ item['receiver_location'] }}</td>
                                    <td class="tw-text-center">
                                        {{ item['number_of_package'] }}
                                    </td>
                                    <!-- <td class="tw-text-center">
                                        {{ item["fx_rate"] }}
                                    </td> -->
                                    <td class="tw-text-center">
                                        <ts-accounting-format
                                            :digit="2"
                                            :value="item.cod_amount"
                                        ></ts-accounting-format>
                                    </td>
                                    <td class="tw-text-center">
                                        <ts-accounting-format
                                            :digit="2"
                                            :value="item.total_fee"
                                        ></ts-accounting-format>
                                    </td>
                                    <td class="tw-text-center">
                                        <ts-accounting-format
                                            :digit="2"
                                            :value="item.amount_tobe_clear"
                                        ></ts-accounting-format>
                                    </td>
                                    <td class="tw-text-center">
                                        <ts-accounting-format
                                            :digit="2"
                                            :value="item.cod_amount_usd_cleared"
                                        ></ts-accounting-format>
                                    </td>
                                    <td class="tw-text-center">
                                        <ts-accounting-format
                                            currency="៛"
                                            :digit="0"
                                            :value="item.cod_amount_khr_cleared"
                                        ></ts-accounting-format>
                                    </td>
                                </tr>
                            </template>
                        </template>
                        <tr
                            :key="indexDate + '_' + shopName + '_'"
                            class="tw-bg-gray-100"
                        >
                            <td
                                class="tw-text-blue-800 tw-font-semibold tw-text-right"
                                colspan="5"
                            >
                                Total For: {{ shopName }}
                            </td>
                            <td
                                class="tw-text-blue-800 tw-font-semibold tw-text-right"
                            >
                                <ts-accounting-format
                                    :digit="2"
                                    :value="sumByShop(shopItem).cod_amount"
                                />
                            </td>
                            <td
                                class="tw-text-blue-800 tw-font-semibold tw-text-right"
                            >
                                <ts-accounting-format
                                    :digit="2"
                                    :value="sumByShop(shopItem).total_fee"
                                />
                            </td>
                            <td
                                class="tw-text-blue-800 tw-font-semibold tw-text-right"
                            >
                                <ts-accounting-format
                                    :digit="2"
                                    :value="
                                        sumByShop(shopItem).amount_tobe_clear
                                    "
                                />
                            </td>
                            <td
                                class="tw-text-blue-800 tw-font-semibold tw-text-right"
                            >
                                <ts-accounting-format
                                    :digit="2"
                                    :value="
                                        sumByShop(shopItem)
                                            .cod_amount_usd_cleared
                                    "
                                />
                            </td>
                            <td
                                class="tw-text-blue-800 tw-font-semibold tw-text-right"
                            >
                                <ts-accounting-format
                                    currency="៛"
                                    :digit="0"
                                    :value="
                                        sumByShop(shopItem)
                                            .cod_amount_khr_cleared
                                    "
                                />
                            </td>
                        </tr>
                    </template>
                    <tr :key="indexDate + '__'">
                        <td height="20" colspan="10"></td>
                    </tr>
                </template>
            </tbody>
            <tfoot v-if="resources.amount_group">
                <tr>
                    <td colspan="5" class="tw-text-right">
                        <strong>Grand Total:</strong>
                    </td>
                    <td class="tw-text-right">
                        <ts-accounting-format
                            currency="$"
                            :digit="2"
                            :value="resources.amount_group.amount_cod"
                            class="tw-font-extrabold tw-text-blue-600"
                        />
                    </td>
                    <td class="tw-text-right">
                        <ts-accounting-format
                            currency="$"
                            :digit="2"
                            :value="resources.amount_group.total_fee"
                            class="tw-font-extrabold tw-text-blue-600"
                        />
                    </td>
                    <td class="tw-text-right">
                        <ts-accounting-format
                            currency="$"
                            :digit="2"
                            :value="resources.amount_group.amount_tobe_clear"
                            class="tw-font-extrabold tw-text-blue-600"
                        />
                    </td>
                    <td class="tw-text-right">
                        <ts-accounting-format
                            currency="$"
                            :digit="2"
                            :value="
                                resources.amount_group.cod_amount_usd_cleared
                            "
                            class="tw-font-extrabold tw-text-blue-600"
                        />
                    </td>
                    <td class="tw-text-right">
                        <ts-accounting-format
                            currency="៛"
                            :digit="0"
                            :value="
                                resources.amount_group.cod_amount_khr_cleared
                            "
                            class="tw-font-extrabold tw-text-blue-600"
                        />
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>
import { sumBy } from 'lodash'
export default {
    name: 'shopCompensateComponent',
    props: ['resources'],
    methods: {
        sumByColumn (resources, column) {
            return sumBy(resources, column)
        },
        sumByShop (resources) {
            var items = []

            Object.values(resources).forEach(element => {
                element.forEach(i => {
                    items.push(i)
                })
            })

            return {
                cod_amount: sumBy(items, 'cod_amount'),
                total_fee: sumBy(items, 'total_fee'),
                amount_tobe_clear: sumBy(items, 'amount_tobe_clear'),
                cod_amount_usd_cleared: sumBy(items, 'cod_amount_usd_cleared'),
                cod_amount_khr_cleared: sumBy(items, 'cod_amount_khr_cleared')
            }
        }
    }
}
</script>

<style lang="css" scoped>
::-webkit-scrollbar {
    width: 8px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: rgb(161, 3, 3);
}
.table > thead {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 999;
    background-color: rgba(59, 130, 246);
}
.table > tbody > tr > td {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    border: none !important;
}

.table > tfoot {
    position: sticky;
    bottom: 0;
    z-index: 1;
    background-color: white;
}
</style>
